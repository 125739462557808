
import {createContext, useContext} from "react";

/*
import { 
    useLocationAnalysis, 
    useLocationMapAnalysisDetail, 
    useLocationMapAnalysis, 
    useLocationMapDot,
    useLocationVender
    } from "__api/requestDistributorData";
*/

export const SiteContext = createContext(
    {
        field_department : {},       
        login_info : {},
    }
);

export const SiteContextProvider = SiteContext.Provider;
export { useContext } ;










